export const download = (name, blob) => {
  // const byteCharacters = new Uint8Array(blob.length);
  // for (let i = 0; i < blob.length; i++) {
  //   byteCharacters[i] = blob.charCodeAt(i);
  // }
  //
  // const data = new Blob([byteCharacters], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  console.log(url);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', name);

  link.addEventListener('click', () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
    });
  });

  link.click();
};