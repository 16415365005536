import axiosClient from "./axiosWrapper";

// EXAMPLE
// {
//   "template": "string",
//   "sender": "ROSGIDROMET",
//   "earthquakeDate": "2024-07-30T21:22:54.970Z",
//   "latitude": 0,
//   "longitude": 0,
//   "magnitude": 0
// }

export const getTemplates = async () => {
  let result = await axiosClient().get(`/expert/templates`)
  return result
}

export const parseMessage = async (messageId) => {
  let result = await axiosClient().post(`/expert/parse`, {messageId: messageId})
  return result
}

export const prepareFile = async (data) => {
  let result = await axiosClient().post(`/expert/prepare`, data)
  return result
}

export const sendDocs = async (data) => {
  let result = await axiosClient().post(`/expert/send`, data)
  return result
}

// {
//   "userId": 0,
//   "signalId": 0,
//   "messageId": 0,
//   "dateTime": "2024-11-25T15:24:46.002Z"
// }
export const getExpertDocuments = async (userId, signalId, messageId, dateTime) => {
// export const getExpertDocuments = async () => {
  let params = {}
  if (userId) params.userId = userId
  if (signalId) params.signalId = signalId
  if (messageId) params.messageId = messageId
  if (dateTime) {
    let startTime = new Date(dateTime)
    startTime.setDate(startTime.getDate() - 1)
    let endTime = new Date(dateTime)
    endTime.setDate(endTime.getDate() + 1)

    params.startDate = startTime
    params.endDate = endTime
  }

  let result = await axiosClient().post(`/expert/documents`, params)
  // let result = await axiosClient().post(`/expert/documents`, {})
  return result
}

export const getRawDocument = async (documentId) => {
  let result = await axiosClient().getBlob(`/expert/raw_document/${documentId}`)
  return result
}

export const getDocument = async (documentId) => {
  let result = await axiosClient().get(`/expert/document/${documentId}`)
  return result
}